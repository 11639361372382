import { default as forgot_45passwordYiAlXkLIh0Meta } from "/var/www/html/pages/auth/forgot-password.vue?macro=true";
import { default as loginRDJc48mj2VMeta } from "/var/www/html/pages/auth/login.vue?macro=true";
import { default as registertTmjjrADHMMeta } from "/var/www/html/pages/auth/register.vue?macro=true";
import { default as cguxfYZE3MLX3Meta } from "/var/www/html/pages/cgu.vue?macro=true";
import { default as indexIgO5MnNvfXMeta } from "/var/www/html/pages/dashboard/index.vue?macro=true";
import { default as mes_45alertesTpKG1j1FsVMeta } from "/var/www/html/pages/dashboard/mes-alertes.vue?macro=true";
import { default as mes_45demandes_45de_45trajetsSEvkeqQBnMMeta } from "/var/www/html/pages/dashboard/mes-demandes-de-trajets.vue?macro=true";
import { default as mes_45trajets_45publies8ImTEhZ8aKMeta } from "/var/www/html/pages/dashboard/mes-trajets-publies.vue?macro=true";
import { default as dashboardihhmfDpFogMeta } from "/var/www/html/pages/dashboard.vue?macro=true";
import { default as guide3pqSUlDv04Meta } from "/var/www/html/pages/guide.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as mentions_45legalesGMoNE98OGmMeta } from "/var/www/html/pages/mentions-legales.vue?macro=true";
import { default as nos_45services7tX1Z8Hf4pMeta } from "/var/www/html/pages/nos-services.vue?macro=true";
import { default as notre_45concept8cbMbUcv6DMeta } from "/var/www/html/pages/notre-concept.vue?macro=true";
import { default as politique_45cookie6rmTD5BSOtMeta } from "/var/www/html/pages/politique-cookie.vue?macro=true";
import { default as politique_45de_45confidentialiteeAieU6p5WXMeta } from "/var/www/html/pages/politique-de-confidentialite.vue?macro=true";
import { default as publier_45un_45trajetAYlDJDeHWQMeta } from "/var/www/html/pages/publier-un-trajet.vue?macro=true";
import { default as rechercher_45un_45trajetrGff1FFNntMeta } from "/var/www/html/pages/rechercher-un-trajet.vue?macro=true";
import { default as resultats1VSIyPQ0DHMeta } from "/var/www/html/pages/resultats.vue?macro=true";
export default [
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("/var/www/html/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/var/www/html/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/var/www/html/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    component: () => import("/var/www/html/pages/cgu.vue").then(m => m.default || m)
  },
  {
    name: dashboardihhmfDpFogMeta?.name,
    path: "/dashboard",
    meta: dashboardihhmfDpFogMeta || {},
    component: () => import("/var/www/html/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard",
    path: "",
    component: () => import("/var/www/html/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mes-alertes",
    path: "mes-alertes",
    meta: mes_45alertesTpKG1j1FsVMeta || {},
    component: () => import("/var/www/html/pages/dashboard/mes-alertes.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mes-demandes-de-trajets",
    path: "mes-demandes-de-trajets",
    meta: mes_45demandes_45de_45trajetsSEvkeqQBnMMeta || {},
    component: () => import("/var/www/html/pages/dashboard/mes-demandes-de-trajets.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mes-trajets-publies",
    path: "mes-trajets-publies",
    meta: mes_45trajets_45publies8ImTEhZ8aKMeta || {},
    component: () => import("/var/www/html/pages/dashboard/mes-trajets-publies.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "guide",
    path: "/guide",
    component: () => import("/var/www/html/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/var/www/html/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "nos-services",
    path: "/nos-services",
    component: () => import("/var/www/html/pages/nos-services.vue").then(m => m.default || m)
  },
  {
    name: "notre-concept",
    path: "/notre-concept",
    component: () => import("/var/www/html/pages/notre-concept.vue").then(m => m.default || m)
  },
  {
    name: "politique-cookie",
    path: "/politique-cookie",
    component: () => import("/var/www/html/pages/politique-cookie.vue").then(m => m.default || m)
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/var/www/html/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "publier-un-trajet",
    path: "/publier-un-trajet",
    meta: publier_45un_45trajetAYlDJDeHWQMeta || {},
    component: () => import("/var/www/html/pages/publier-un-trajet.vue").then(m => m.default || m)
  },
  {
    name: "rechercher-un-trajet",
    path: "/rechercher-un-trajet",
    component: () => import("/var/www/html/pages/rechercher-un-trajet.vue").then(m => m.default || m)
  },
  {
    name: "resultats",
    path: "/resultats",
    component: () => import("/var/www/html/pages/resultats.vue").then(m => m.default || m)
  }
]